import ApiService from '@/core/services/api.service';

// action types
// export const GET_ADMINEDIT_SITE_INFO = "getAdminEditSiteId";

// mutation types
export const SET_ADMINEDIT_SITE_INFO = 'setAdminEditSiteInfo';
export const ADD_SITE_ACCOUNTLINK = 'addSiteAccountLink';
export const REFRESH_ADMINEDIT_SITE = 'refreshSite';
export const LOAD_ADMINEDIT_ACCOUNT = 'loadEditAccount';
export const REMOVE_SITE_ACCOUNTLINK = 'removeSiteAccountLink';
export const TOGGLE_QUOTA = 'toggleSiteQuota';
export const REMOVE_QUOTA = 'removeSiteQuota';
export const UPDATE_SITE_QUOTA = 'updateSiteQuota';
export const SET_AVAILABLE_WIDGETS = 'setAvailableWidgets';
export const SET_DEVICE_TYPES = 'setDeviceTypes';
export const UPDATE_SITE_PROFILE = 'updateSiteProfile';
export const SET_LOADING_STATE = 'setLoadingState';
export const SET_ADMIN_SITE_INFO = 'setAdminSiteInfo';
export const SET_ADMIN_EDIT_ACCOUNT = 'setAdminEditAccount';
export const SET_ADMIN_ACCOUNT_INFO = 'setAdminAccountInfo';

export const TOGGLE_STARLINK_SUBSCRIPTION = 'toggleStarlinkSubscription';
export const UPDATE_STARLINK_SUBSCRIPTION = 'updateSiteStarlinkSubscription';
export const SET_STARLINK_SERVICELINES = 'setStarlinkServiceLines'
export const REMOVE_STARLINK_QUOTA = 'removeStarlinkQuota';

const state = {
	admin_site: {
		id: 'loading...',
		name: 'loading...',
		icon: '',
		notes: '',
		data: {
			linkedAccounts: null,
			accounts: null,
		},
		showQuota: false,
		quotaStatus: '',
		// ----
		starlinkServiceLines: [],
		showStarlinkSubscription: false,
		starlinkQuotaStatus: '',
		// ----
		availableWidgets: [],
		loading: false,
	},
	admin_account: {
		loading: false,
		AccountID: null,
		Admin: null,
		DateAdded: '',
		OBA_CustomerID: null,
		acctmgremail: null,
		adminfirst: false,
		allowsnmp: false,
		avatar: null,
		avatar_thumb: null,
		children: [],
		email: '',
		engineer: false,
		lastip: '',
		lastlogin: '',
		layout: {},
		name: null,
		parent: {},
		password_changed: null,
		pendingsrf: null,
		reports: [],
		role: null,
		showapi: null,
		showlinecards: null,
		showoptionfile: null,
		showrealtime: null,
		showsrf: null,
		showtickets: null,
		sites: [],
		unmonitored_sites: [],
		username: null,
		videorooms: [],
		videoroomsuser: [],
		voipcount: null,
		data: {},
	},
	deviceTypes: {},
};

const getters = {
	adminSite(state) {
		return state.admin_site;
	},
	deviceTypes(state) {
		return state.deviceTypes;
	},
	adminAccount(state) {
		return state.admin_account;
	},
	adminSiteLoading(state) {
		return state.admin_site.loading;
	},
};

const actions = {
	[SET_ADMINEDIT_SITE_INFO](state, SiteId) {
		state.commit(SET_LOADING_STATE, { stateKey: 'admin_site', value: true });
		ApiService.get(`adminsite/${SiteId}`).then(res => {
			state.commit(SET_ADMIN_SITE_INFO, res.data.data);
			state.commit(SET_LOADING_STATE, { stateKey: 'admin_site', value: false });
			return res.data.data.devices;
		})
		.then(devices => {
			const serviceLines = [];
			devices.map(device => {
				if (device.TypeId === 23) {
				const source = JSON.parse(device.Source);
				serviceLines.push(source.serviceLineNumber);
				}
			});
			if (serviceLines.length > 0) {
				const uniqueServiceLines = [...new Set(serviceLines)];
				state.commit(SET_STARLINK_SERVICELINES, uniqueServiceLines);
			} else {
				state.commit(SET_STARLINK_SERVICELINES, serviceLines);
			}
		});
	},
	[SET_ADMIN_EDIT_ACCOUNT](context, id) {
		context.commit(SET_LOADING_STATE, { stateKey: 'admin_account', value: true });
		ApiService.get(`account/${id}`)
			.then(res => {
				context.commit(SET_ADMIN_ACCOUNT_INFO, res.data.data);
				context.commit(SET_LOADING_STATE, { stateKey: 'admin_account', value: false });
			})
			.catch(e => {
				context.commit(SET_LOADING_STATE, { stateKey: 'admin_account', value: false });
			});
	},
};

const mutations = {
	[SET_STARLINK_SERVICELINES](state, uniqueServiceLines) {
		state.admin_site.starlinkServiceLines = uniqueServiceLines;
	},

	[SET_LOADING_STATE](state, payload) {
		state[payload.stateKey].loading = payload.value;
	},
	[SET_ADMIN_SITE_INFO](state, data) {
		console.log('setting admin site info', data)
		state.admin_site.id = data.SiteId;
		state.admin_site.name = data.Name.trim();
		state.admin_site.icon = data.icon;
		state.admin_site.notes = data.notes;
		state.admin_site.data = data;
		if(data.starlinkQuotas) {
			state.admin_site.showStarlinkSubscription = true;
			state.admin_site.starlinkQuotaStatus = 'added';
		} else {
			state.admin_site.showStarlinkSubscription = false;
			state.admin_site.starlinkQuotaStatus = '';
		}
		if ('quota' in data && data.quota) {
			state.admin_site.showQuota = true;
			state.admin_site.quotaStatus = 'added';
		} else {
			state.admin_site.showQuota = false;
			state.admin_site.quotaStatus = '';
		}
	},

	[REFRESH_ADMINEDIT_SITE](state) {
		state.admin_site.loading = true;
		if (state.admin_site.id != 'loading...') {
			ApiService.get(`adminsite/${state.admin_site.id}`).then(res => {
				state.admin_site.id = res.data.data.SiteId;
				state.admin_site.name = res.data.data.Name.trim();
				state.admin_site.icon = res.data.data.icon;
				for (let key in res.data.data) {
					state.admin_site.data[key] = res.data.data[key];
				}

				if (typeof res.data.data.quota !== 'undefined') {
					state.admin_site.showQuota = true;
					state.admin_site.quotaStatus = 'added';
				}
				state.admin_site.loading = false;
			});
		}
	},
	[LOAD_ADMINEDIT_ACCOUNT](state, id) {
		state.admin_account.loading = true;
		ApiService.get(`account/${id}`)
			.then(res => {
				state.admin_account.data = res.data.data;
				state.admin_account.avatar = res.data.data.avatar;
				state.admin_account.loading = false;
			})
			.catch(e => {
				console.log('ERROR LOADING ACCOUNT: ' + id);
				state.admin_account.loading = false;
			});
	},
	[SET_ADMIN_ACCOUNT_INFO](state, payload) {
		for (let key in payload) {
			state.admin_account[key] = payload[key];
		}
		state.admin_account.data = payload;
	},
	[SET_DEVICE_TYPES](state, data) {
		state.deviceTypes = data;
	},
	[ADD_SITE_ACCOUNTLINK](state, payload) {
		state.admin_site.data.linkedAccounts.push({ AccountId: payload.AccountID, SiteId: state.admin_site.data.SiteId });
		state.admin_site.data.accounts.push(payload);
	},
	[REMOVE_SITE_ACCOUNTLINK](state, payload) {
		state.admin_site.data.accounts = state.admin_site.data.accounts.filter(f => f.AccountID != payload.AccountId);
		state.admin_site.data.linkedAccounts = state.admin_site.data.linkedAccounts.filter(
			f => f.AccountId != payload.AccountId && f.SiteId != payload.SiteId
		);
	},

	[TOGGLE_QUOTA](state) {
		state.admin_site.showQuota = !state.admin_site.showQuota;
		if (state.admin_site.quotaStatus === '' && state.admin_site.showQuota) state.admin_site.quotaStatus = 'addingQuota';
	},
	[TOGGLE_STARLINK_SUBSCRIPTION](state) {
		state.admin_site.showStarlinkSubscription = !state.admin_site.showStarlinkSubscription;
		if (state.admin_site.starlinkQuotaStatus === '' && state.admin_site.showStarlinkSubscription) state.admin_site.starlinkQuotaStatus = 'addingStarlinkQuota';
	},

	[REMOVE_QUOTA](state, payload) {
		state.admin_site.showQuota = false;
		state.admin_site.quotaStatus = '';
		if (payload === 'deleted') {
			delete state.admin_site.data.quota;
		}
	},

	[REMOVE_STARLINK_QUOTA](state, payload) {
		state.admin_site.showStarlinkSubscription = false;
		state.admin_site.starlinkQuotaStatus = '';
	},

	[UPDATE_SITE_QUOTA](state, payload) {
		state.admin_site.data.quota = {};
		state.admin_site.data.quota['SiteId'] = payload.SiteId;
		state.admin_site.data.quota['email'] = payload.email;
		state.admin_site.data.quota['quota'] = payload.quota;
		state.admin_site.data.quota['warnpercent'] = payload.warnpercent;
		if (payload.quotaStatus === 'added') {
			state.admin_site.quotaStatus = 'added';
		}
	},
	[SET_AVAILABLE_WIDGETS](state, payload) {
		state.admin_site.availableWidgets = payload;
	},
	[UPDATE_SITE_PROFILE](state, payload) {
		state.admin_site.name = payload.name;
		state.admin_site.icon = payload.icon;
		state.admin_site.data.notes = payload.notes;
	},

	[UPDATE_STARLINK_SUBSCRIPTION](state, payload) {
		state.admin_site.data.starlinkSubscription = {};
		state.admin_site.data.starlinkSubscription['SiteId'] = payload.SiteId;
		state.admin_site.data.starlinkSubscription['email'] = payload.email;
		state.admin_site.data.starlinkSubscription['quota'] = payload.quota;
		state.admin_site.data.starlinkSubscription['warnpercent'] = payload.warnpercent;
		if (payload.starlinkQuotaStatus === 'added') {
			state.admin_site.starlinkQuotaStatus = 'added';
		}
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
