import ApiService from '@/core/services/api.service';

//mutation types
export const START_SITES_STATUS = 'startSitesStatus';
export const STOP_SITES_STATUS = 'stopSitesStatus';
export const START_VOIP_STATUS = 'startVoipStatus';
export const STOP_VOIP_STATUS = 'stopVoipStatus';
export const START_ALERT_STATUS = 'startAlertStatus';
export const STOP_ALERT_STATUS = 'stopAlertStatus';
export const START_GLOBALALERT_STATUS = 'startGlobalAlertStatus';
export const STOP_GLOBALALERT_STATUS = 'stopGlobalAlertStatus';
export const GET_USER_ALERT_BANNER = 'getUserAlertStatus';
export const GET_SITE_ALERT_BANNER = 'getSiteAlertStatus';
export const CLEAR_BANNER = 'clearBanner';

const state = {
	sites: null,
	voip: null,
	alert: null,
	globalalert: null,
	sitesInt: null,
	voipInt: null,
	alertInt: null,
	globalalertInt: null,
	alertBanner: {
		show: false,
		message: '',
	},
};

const getters = {
	sitesStatus(state) {
		return state.sites;
	},
	voipStatus(state) {
		return state.voip;
	},
	alertStatus(state) {
		return state.alert;
	},
	globalAlertStatus(state) {
		return state.globalalert;
	},
	alertBanner(state) {
		return state.alertBanner;
	},
};

const actions = {
	startSitesStatus({ commit, rootState }) {
		let interval = setInterval(() => {
			if (rootState.auth.isAuthenticated) {
				ApiService.get('sitelistalarms').then(res => {
					commit('setSitesStatus', { data: res.data.data, interval: null });
				});
			}
		}, 30000);
		if (rootState.auth.isAuthenticated) {
			ApiService.get('sitelistalarms').then(res => {
				commit('setSitesStatus', { data: res.data.data, interval: interval });
			});
		}
	},
	startVoipStatus({ commit, rootState }) {
		let interval = setInterval(() => {
			if (rootState.auth.isAuthenticated) {
				ApiService.get('tsip/currentcalls/account').then(res => {
					if (res.data?.data) {
						commit('setVoipStatus', { data: res.data.data, interval: null });
					}
				});
			}
		}, 30000);
		if (rootState.auth.isAuthenticated) {
			ApiService.get('tsip/currentcalls/account').then(res => {
				commit('setVoipStatus', { data: res.data.data, interval: interval });
			});
		}
	},
	startAlertStatus({ commit, rootState }) {
		let interval = setInterval(() => {
			if (rootState.auth.isAuthenticated) {
				ApiService.get('sitelistalarms').then(res => {
					commit('setAlertStatus', { data: res.data.data, interval: null });
				});
			}
		}, 30000);
		if (rootState.auth.isAuthenticated) {
			ApiService.get('sitelistalarms').then(res => {
				commit('setAlertStatus', { data: res.data.data, interval: interval });
			});
		}
	},
	startGlobalAlertStatus({ commit, rootState }) {
		let interval = setInterval(() => {
			if (rootState.auth.isAuthenticated) {
				ApiService.get('sitelistalarms').then(res => {
					commit('setGlobalAlertStatus', { data: res.data.data, interval: null });
				});
			}
		}, 30000);
		if (rootState.auth.isAuthenticated) {
			ApiService.get('sitelistalarms').then(res => {
				commit('setGlobalAlertStatus', { data: res.data.data, interval: interval });
			});
		}
	},
	getUserAlertStatus({ commit }, user) {
		ApiService.get(`/alertbanner/account/${user.AccountID}`).then(({ data }) => {
			commit('setAlertStatus', data);
		});
	},
	getSiteAlertStatus({ commit }, siteId) {
		ApiService.get(`/alertbanner/site/${siteId}`).then(({ data }) => {
			commit('setAlertStatus', data);
		});
	},
};

const mutations = {
	setSitesStatus(state, data) {
		state.sites = data.data;
		if (data.interval) {
			state.siteInt = data.interval;
		}
	},
	stopSitesStatus(state) {
		clearInterval(state.siteInt);
		state.sites = null;
	},
	setVoipStatus(state, data) {
		state.voip = data.data;
		if (data.interval) {
			state.voipInt = data.interval;
		}
	},
	stopVoipStatus(state) {
		clearInterval(state.voipInt);
		state.voip = null;
	},
	setAlertStatus(state, data) {
		state.alertBanner.show = data.alert;
		state.alertBanner.message = data.message;
	},
	clearBanner(state) {
		state.alertBanner.show = false;
		state.alertBanner.message = '';
	},
	// setAlertStatus(state, data) {
	// 	state.alert = data.data;
	// 	if (data.interval) {
	// 		state.alertInt = data.interval;
	// 	}
	// },
	stopAlertStatus(state) {
		clearInterval(state.alertInt);
		state.alert = null;
	},
	setGlobalAlertStatus(state, data) {
		state.globalalert = data.data;
		if (data.interval) {
			state.globalalertInt = data.interval;
		}
	},
	stopGlobalAlertStatus(state) {
		clearInterval(state.globalalertInt);
		state.globalalert = null;
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
