//action types
export const ENABLE_DATERANGE = 'enableDateRange';
export const REMOVE_DATERANGE = 'removeDateRange';

//mutation types
export const GET_DATERANGE = 'getDateRange';
export const SET_DATERANGE = 'setDateRange';
export const PURGE_DATERANGE = 'purgeDateRange';

const state = {
    start: null,
    end: null,
};

const getters = {
    DateRange(state) {
        return state;
    }
};

const actions = {
    [ENABLE_DATERANGE](context, data) {
        context.commit(SET_DATERANGE, data);
    },
    [REMOVE_DATERANGE](context) {
        context.commit(PURGE_DATERANGE);
    }
};

const mutations = {
    [GET_DATERANGE](state) {
        const va = localStorage.getItem('DateRange');
        const data = JSON.parse(va);
        if (data != null) {
            state.start = data.start;
            state.end = data.end;
        }
    },
    [SET_DATERANGE](state, data) {
        state.start = data.start;
        state.end = data.end;
    },
    [PURGE_DATERANGE](state) {
        state.start = null;
        state.end = null;
    }
}

export default {
    state,
    actions,
    mutations,
    getters,
};
