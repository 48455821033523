if ('serviceWorker' in navigator) {
	navigator.serviceWorker
		.register('../../service-worker.js')
		.then(() => {
			navigator.serviceWorker.addEventListener('message', event => {
				if (event.data.action === 'RELOAD') {
					window.location.reload();
				}
			});
		})
		.catch(error => {
			console.log('Registration failed' + error);
		});
}
