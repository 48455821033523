import Cookies from "js-cookie";

const TOKEN_KEY = "token";

export const getToken = () => {
	return Cookies.get(TOKEN_KEY);
};

export const setCookie = token => {
	Cookies.set(TOKEN_KEY, token, { expires: 0.125 });
};

export const saveToken = token => {
	localStorage.setItem(TOKEN_KEY, token);
};

export const destroyToken = () => {
	Cookies.remove(TOKEN_KEY);
};

export default { getToken, saveToken, destroyToken, setCookie };
