import ApiService from '@/core/services/api.service';
// action types
export const UPDATE_USER_INFO = 'updateUserInfo';
export const GET_USER_PROFILE = 'getUserProfile';
export const SET_USER = 'setUser';

// mutation types
export const SET_USER_PROFILE = 'setUserInfo';
export const SET_NEW_INFO = 'setNewInfo';
export const SET_ERROR = 'setError';

const state = {
	AccountID: null,
	username: null,
	name: null,
	email: null,
	DateAdded: null,
	Admin: null,
	lastlogin: null,
	lastip: null,
	adminfirst: null,
	password_changed: null,
	avatar_thumb: null,
	avatar: null,
	showlinecards: false,
	showrealtime: false,
	showoptionfile: false,
	showapi: false,
	showsrf: false,
	role: null,
	region: null,
	department: null,
	layout: {
		gridster: [],
	},
	errors: [],
};

const getters = {
	currentUser(state) {
		return state;
	},

	currentUserAvatar(state) {
		return state.avatar;
	},

	currentUserAvatarThumb(state) {
		return state.avatar_thumb;
	},
};

const actions = {
	[GET_USER_PROFILE](context, payload) {
		return new Promise((resolve, reject) => {
			ApiService.get('profile')
				.then(res => {
					// console.log("Profile/", res.data)
					context.commit(SET_USER_PROFILE, res.data.data);
					resolve();
				})
				.catch(res => {
					let msg = JSON.parse(res);
					context.commit(SET_ERROR, [msg.msg]);
					reject();
				});
		});
	},
	[SET_USER]({ commit, dispatch, rootGetters }) {
		const user = JSON.parse(localStorage.getItem('user'));
		if (user && user.AccountID) {
			commit(SET_USER_PROFILE, user);
		} else if (rootGetters.isAuthenticated) {
			dispatch(GET_USER_PROFILE);
		}
	},
	[UPDATE_USER_INFO](context, payload) {
		context.commit(SET_NEW_INFO, payload);
		ApiService.post('profile', payload)
			.then(res => {
				context.commit(SET_USER_PROFILE, res.data.data);
			})
			.catch(res => {
				let msg = JSON.parse(res);
				context.commit(SET_ERROR, [msg.msg]);
			});
	},
};

const mutations = {
	[SET_USER_PROFILE](state, data) {
		Object.keys(data).forEach(k => {
			state[k] = data[k];
		});
		localStorage.setItem('user', JSON.stringify(state));
	},
	[SET_NEW_INFO](state, payload) {
		// state.user_info = [...state.user_info, payload];
		state.name = payload.name;
		state.email = payload.email;
		state.adminfirst = payload.adminfirst;
	},
	[SET_ERROR](state, error) {
		state.errors = error;
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
