import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from '@/core/services/store';
import ApiService from '@/core/services/api.service';
import { VERIFY_AUTH } from '@/core/services/store/auth.module';
import { RESET_LAYOUT_CONFIG } from '@/core/services/store/config.module';

Vue.config.productionTip = false;

// Global 3rd party plugins
import 'popper.js';
import 'tooltip.js';
import PerfectScrollbar from 'perfect-scrollbar';
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from 'clipboard';
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
// import i18n from '@/core/plugins/vue-i18n';
import vuetify from '@/core/plugins/vuetify';
import '@/core/plugins/portal-vue';
import '@/core/plugins/bootstrap-vue';
import '@/core/plugins/perfect-scrollbar';
import '@/core/plugins/highlight-js';
import '@/core/plugins/inline-svg';
import '@/core/plugins/apexcharts';
import '@/core/plugins/metronic';
import '@mdi/font/css/materialdesignicons.css';

//STYLESHEETS
import './assets/custom-styles/icons.css';

//register srvice worker
import '@/core/services/registerServiceWorker.js';

ApiService.store = store;
Object.defineProperty(Vue.prototype, '$http', { value: ApiService });

// router.beforeEach((to, from, next) => {
// 	// const isAuthenticated = store.getters.isAuthenticated;
// 	let isAuthenticated = false;
// 	console.log("Check auth", isAuthenticated)
// 	if (to.path !== '/login' && !isAuthenticated) {
// 	  // If the user is not authenticated and they're trying to access a protected route
// 	  next('/login'); // Redirect them to the login page
// 	} else if (to.path === '/login' && isAuthenticated) {
// 	  // If the user is authenticated and trying to access the login page
// 	  next('/'); // Redirect them to the landing page
// 	} else {
// 	  // For all other cases, allow the navigation
// 	  next();
// 	}
//   });

// router.beforeEach((to, from, next) => {
// 	console.log('running router.beforeEach')
// 	let isAuthenticated = store.getters.isAuthenticated;
// 	const currentUserRole = store.getters.currentUser.role;
//   isAuthenticated = true;
// 	if (to.path == '/login') {
// 	  // If the user is already authenticated, redirect them away from the login page
// 	  if (isAuthenticated) {
// 		next('/'); // Redirect to the landing page
// 	  } else {
// 		next(); // Allow navigation to the login page
// 	  }
// 	} else if (currentUserRole == 'video' && !['/app/video', '/app/profile', '/404'].includes(to.path)) {
// 	  next({ path: '/app/video' }); // Redirect based on role and path conditions
// 	} else if (!isAuthenticated) {
// 	  next('/login'); // Redirect to login for all other cases when not authenticated
// 	} else {
// 	  // For authenticated users navigating to other routes, proceed with navigation
// 	  next();
// 	}
//   });

router.beforeResolve((to, from, next) => {
	// console.log('Checking route:', to.path);
	// to.path == '/login' || 
	// || to.path.includes('callback#') || window.location.hash === '#callback'
	if (to.path == '/login' || to.path == '/callback') {
		// console.log('Matched /login, /callback, or callback#');
		next();
	} else if (store.getters.currentUser.role == 'video' && !['/app/video', '/app/profile', '/404'].includes(to.path)) {
		next({ path: '/app/video' });
	} else {
		// console.log('checking auth.....')
		Promise.all([store.dispatch(VERIFY_AUTH, to.path)]).then(next);
	}
});

new Vue({
	router,
	store,
	// i18n,
	// vuetify,
	render: h => h(App),
}).$mount('#app');
