//action types
export const ENABLE_VIEWAS = 'enableViewAs';
export const REMOVE_VIEWAS = 'removeViewAs';

//mutation types
export const GET_VIEWAS = 'getViewAs';
export const SET_VIEWAS = 'setViewAs';
export const PURGE_VIEWAS = 'purgeViewAs';

const state = {
    AccountID: null,
    name: null,
    username: null,
    avatar: null
};

const getters = {
    viewAs(state) {
        return state;
    }
};

const actions = {
    [ENABLE_VIEWAS](context, data) {
        context.commit(SET_VIEWAS, data);
    },
    [REMOVE_VIEWAS](context) {
        context.commit(PURGE_VIEWAS);
    }
};

const mutations = {
    [GET_VIEWAS](state) {
        const va = localStorage.getItem('viewAs');
        const data = JSON.parse(va);
        if(data != null){
            state.AccountID = data.AccountID;
            state.name = data.name;
            state.username = data.username;
            state.avatar = data.avatar;                        
        }
    },
    [SET_VIEWAS](state, data) {
        state.AccountID = data.AccountID;
        state.name = data.name;
        state.username = data.username;
        state.avatar = data.avatar;        
        localStorage.setItem('viewAs', JSON.stringify(data));        
    },
    [PURGE_VIEWAS](state) {
        state.name = null;
        state.AccountID = null;
        state.username = null;
        state.avatar = null;
        localStorage.removeItem('viewAs');
    }
}

export default {
    state,
    actions,
    mutations,
    getters,
};
