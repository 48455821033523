// mutation types
export const SET_READY_STATE = "setReadyState";
export const SET_READY_COMPONENTS = "setReadyComponents";

export default {
	state: {
		ready: false,
		readyComponents: {},
	},
	getters: {
		ready(state) {
			return state.ready;
		},
	},
	mutations: {
		[SET_READY_COMPONENTS](state, components) {
			state.ready = false;
			components.forEach(comp => {
				state.readyComponents[comp] = false;
			});
		},
		[SET_READY_STATE](state, payload) {
			let { component, ready } = payload;
			state.readyComponents[component] = ready;
			let readyState = true;
			for (let comp in state.readyComponents) {
				readyState = readyState && state.readyComponents[comp];
			}
			state.ready = readyState;
		},
	},
};
