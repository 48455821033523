import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import admin from "./admin.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import ready from "./ready.module";
import profile from "./profile.module";
import viewAs from "./viewas.module";
import daterange from "./daterange.module";
import notification from "./notification.module";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		auth,
		admin,
		htmlClass,
		config,
		breadcrumbs,
		ready,
		profile,
		viewAs,
		daterange,
		notification
	},
});
