import ApiService from '@/core/services/api.service';
import JwtService from '@/core/services/jwt.service';

// action types
export const VERIFY_AUTH = 'verifyAuth';
export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const CLEAR_AUTH_COOKIES = 'clear_auth_cookies';
export const CLEAR_ALL_CACHE = 'clear_all_cache';
export const CLEAR_CACHE = 'clear_cache';
export const SET_TOKEN = 'set_token';

// mutation types
export const PURGE_AUTH = 'logOut';
export const SET_AUTH = 'setAuth';
export const SET_USER_AUTH = 'setUserAuth';
export const SET_ERROR = 'setError';
export const SET_TOKEN_M = 'set_token_m'

const capitalizeFirstLetter = (inputString) => {
	return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}

const state = {
	errors: [],
	userAuth: {},
	isAuthenticated: !!JwtService.getToken(),
	requiresPwUpdate: false,
	auth0_token: {}
};

const getters = {
	isAuthenticated(state) {
		return state.isAuthenticated;
	},
	requiresPwUpdate(state) {
		return state.requiresPwUpdate;
	},
	getToken(state) {
		return state.auth0_token;
	}
};

const actions = {
	[SET_TOKEN](context, payload) {
		context.commit(SET_TOKEN_M, payload);
	},
	[LOGIN](context, credentials) {
		return new Promise((resolve, reject) => {
			ApiService.get('login/auth0')
				.then(({ data }) => {
					if (data) {
						context.commit(SET_AUTH, data);
						resolve(data);
					} else {
						context.commit(SET_ERROR, ['Login Failed']);
						reject();
					}
				})
				.catch(error => {
					context.commit(SET_ERROR, [error.response.data]);
					reject(error.response);
				});
		});
	},
	[CLEAR_AUTH_COOKIES](context) {
		ApiService.get('logout/auth0')
			.then(res => {
				// console.log('clear auth cookies', res);
				context.commit(PURGE_AUTH);
			})
			.catch(error => {
				// console.log('error clearing auth cookies', error)
				context.commit(SET_ERROR, [error.response.data]);
			})
			.finally(() => {
				window.location.href = '/login';
			});
	},
	async [LOGOUT]({ commit, dispatch }) {
		commit(PURGE_AUTH);
		await dispatch(CLEAR_ALL_CACHE);
		// await dispatch('reloadAll');
		window.location.href = '/login';
	},
	async [VERIFY_AUTH]({ commit, dispatch, state }, currentPath) {
		if (currentPath === '/login/' || currentPath === '/callback/') {
			return;
		}
		const token = state.auth0_token;
		const route = currentPath == '/' ? '/login' : '/login?redirect=' + currentPath;
		if (token && token !== 'undefined') {
			try {
				// console.log("try verifying auth")
				let { data } = await ApiService.get('verify/auth0');
				// console.log('the try data', data)
				if(data &&  data.name) {
				//  console.log('set auth.......')
					commit(SET_AUTH, data);
				}
				if (!data || !state.userAuth || data.name !== state.userAuth.name) {
					// console.log('false user check and auth')
					commit(PURGE_AUTH);
					await dispatch(CLEAR_ALL_CACHE);
					window.location.href = route;
				}
			} catch {
				// console.log("CATCH Verify_Auth")
				commit(PURGE_AUTH);
				await dispatch(CLEAR_ALL_CACHE);
				window.location.href = route;
			}
		} else {
			// console.log("ELSE purge Verify_Auth")
			commit(PURGE_AUTH);
			await dispatch(CLEAR_ALL_CACHE);
			window.location.href = route;
		}
	},
	async [CLEAR_ALL_CACHE]() {
		let cache = await caches.open('api-cache');
		let keys = await cache.keys();
		keys.forEach(request => cache.delete(request));
	},
	async [CLEAR_CACHE](context, route) {
		let cache = await caches.open('api-cache');
		cache.delete(route);
	},
	async reloadAll() {
		const registrations = await navigator.serviceWorker.getRegistrations();
		for (const registration of registrations) {
			registration.active.postMessage({ action: 'RELOAD' });
		}
	},
};

const mutations = {
	[SET_TOKEN_M](state, payload) {
		state.auth0_token = payload;
	},
	[SET_ERROR](state, error) {
		state.errors = error;
	},
	// [SET_AUTH_OLD](state, data) {
	// 	state.isAuthenticated = true;
	// 	state.requiresPwUpdate = data.pw_update_req;
	// 	let { user } = data;
	// 	localStorage.setItem('userAuth', JSON.stringify(user));
	// 	state.userAuth = user;
	// 	state.errors = {};
	// 	JwtService.setCookie(data.token);
	// },
	[SET_AUTH](state, data) {
		state.isAuthenticated = true;
		state.requiresPwUpdate = false;
		let user = data;
		let fullname = data['email'].split('@')[0];
		let first = capitalizeFirstLetter(fullname[0]);
		// let last = capitalizeFirstLetter(fullname[1]);
		let last ='';
		fullname = first + ' ' + last;
		user.fullname = fullname;
		localStorage.setItem('userAuth', JSON.stringify(user));
		state.userAuth = user;
		state.errors = {};
		// JwtService.setCookie(data.token); //backend will set the cookie when logging in with valid tokens
	},
	[SET_USER_AUTH](state) {
		const user = localStorage.getItem('userAuth');
		state.userAuth = user ? JSON.parse(user) : null;
	},
	[PURGE_AUTH](state) {
		// console.log('purging auth state')
		state.isAuthenticated = false;
		state.userAuth = {};
		state.errors = {};
		localStorage.removeItem('userAuth');
		localStorage.removeItem('user');
		// JwtService.destroyToken();
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
