import axios from 'axios';

/**
 * Service to call HTTP request via Axios
 */
let $http = axios.create({
	baseURL: '/api/',
	timeout: 120000,
	withCredentials: true,
});

const ApiService = {
	store: {},
	init() {
		$http.interceptors.response.use(undefined, function(error) {
			if (error.response?.status === 401 && error.response.config.baseURL === '/api/') {
				this.store.dispatch(LOGOUT);
				return Promise.resolve(error);
			}
			return Promise.reject(error);
		});
	},

	query(resource, params) {
		return $http.get(resource, params).catch(error => {
			this.$store.commit('updateHttpErrors', error);

			throw new Error(`ApiService ${error}`);
		});
	},
	get(resource) {
		var params = {};
		if (this.store.getters.viewAs.username) {
			params.username = this.store.getters.viewAs.username;
		}

		if (this.store.getters.DateRange.start) {
			params.start =
				this.store.getters.DateRange.start.toISOString().split('T')[0] +
				' ' +
				this.store.getters.DateRange.start
					.toISOString()
					.split('T')[1]
					.substring(0, 8);
			params.end =
				this.store.getters.DateRange.end.toISOString().split('T')[0] +
				' ' +
				this.store.getters.DateRange.end
					.toISOString()
					.split('T')[1]
					.substring(0, 8);
		}
		// const excludeHeadersForUrls = ['/login/auth0', '/logout/auth0'];
		// const excludeHeaders = excludeHeadersForUrls.includes(resource);

		return $http.get(resource, { 
			params: params,
			headers: {
				'Authorization': `Bearer ${this.store.getters.getToken.accessToken}`,
				'X-Id-Token': `${this.store.getters.getToken.idToken}`
			  }

		}).catch(error => {
			if (error.response?.status === 401 && error.response.config.baseURL === '/api/') {
				this.store.dispatch('logout');
			}
			// throw new Error(`ApiService ${error}`);
		});
	},

	post(resource, params) {
		return new Promise((resolve, reject) => {
			$http
				.post(resource, params, {
					headers: {
						'Authorization': `Bearer ${this.store.getters.getToken.accessToken}`,
						'X-Id-Token': `${this.store.getters.getToken.idToken}`						
					  }
				})
				.then(response => resolve(response))
				.catch(error => {
					if (error.response.status == 500) {
						if (/Duplicate\sentry\s'\w+'\sfor\skey\s'name'/.test(error.response.data.exception[0].message)) {
							let msg = error.response.data.exception[0].message.split("'");
							console.log(msg);
							return reject(`An entry with ${msg[3]} "${msg[1]}" already exists`);
						} else {
							return reject(error);
						}
					} else {
						return reject(error);
					}
				});
		});
	},

	update(resource, params) {
		return $http.put(resource, params);
	},

	put(resource, params) {
		return $http.put(resource, params, {
			headers: {
				'Authorization': `Bearer ${this.store.getters.getToken.accessToken}`,
				'X-Id-Token': `${this.store.getters.getToken.idToken}`
			}
		});
	},

	delete(resource) {
		return $http.delete(resource, {
			headers: {
				'Authorization': `Bearer ${this.store.getters.getToken.accessToken}`,
				'X-Id-Token': `${this.store.getters.getToken.idToken}`
			}
		}).catch(error => {
			// console.log(error);
			throw new Error(`ApiService ${error}`);
		});
	},
};

export default ApiService;



		// $http.interceptors.request.use(config => {
		// 	const token = this.store.getters.getToken;
		// 	config.headers['Authorization'] = `Bearer ${token.idToken}`;
		// 	console.log(config);
		// })