import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
	mode: 'history',
	routes: [
		{
			path: '/',
			component: () => import('@/view/layout/DashLayout'),
			children: [
				{
					path: '',
					name: 'dash',
					component: () => import('@/view/pages/Dash.vue'),
				},
				{
					path: 'profile',
					name: 'profile',
					component: () => import('@/view/pages/profile/Profile.vue'),
				},
			],
		},
		{
			path: '/login',
			component: () => import('@/view/pages/auth/Auth'),
			children: [
				{
					path: '',
					name: 'login',
					component: () => import('@/view/pages/auth/Login'),
				},
			],
		},
		{
			path: '/callback',
			component: () => import('@/view/pages/auth/Auth'),
			children: [
				{
					path: '',
					name: 'authcallback',
					component: () => import('@/view/pages/auth/AuthCallback'),
				},
			],
		},
		// {
		// 	path: '/testpage',
		// 	component: () => import('@/view/pages/testPage'),
			// children: [
			// 	{
			// 		path: '',
			// 		name: 'authcallback',
			// 		component: () => import('@/view/pages/auth/AuthCallback'),
			// 	},
			// ],
		// },
		{
			path: '/sitelist',
			component: () => import('@/view/layout/Layout'),
			children: [
				{
					path: '/sitelist',
					name: 'sitelist',
					component: () => import('@/view/pages/SiteListPage.vue'),
					props: true,
				},
			],
		},
		{
			path: '/sitelistgraphs',
			component: () => import('@/view/layout/Layout'),
			children: [
				{
					path: '/sitelistgraphs',
					name: 'sitelistgraphs',
					component: () => import('@/view/pages/SiteListGraphs.vue'),
					props: true,
				},
			],
		},
		{
			path: '/site',
			component: () => import('@/view/layout/Layout'),
			children: [
				{
					path: '/site/:id',
					name: 'site',
					component: () => import('@/view/pages/Site.vue'),
					props: true,
				},
			],
		},
		{
			path: '/realtime',
			component: () => import('@/view/layout/LayoutNoHeader'),
			children: [
				{
					path: '/realtime/:id',
					name: 'realtime',
					component: () => import('@/view/pages/Realtime.vue'),
					props: true,
				},
			],
		},
		{
			path: '/ticket',
			component: () => import('@/view/layout/Layout'),
			children: [
				{
					path: '/ticket/:id',
					name: 'ticket',
					component: () => import('@/view/pages/TicketDetails.vue'),
					props: true,
				},
			],
		},
		{
			path: '/tickets',
			component: () => import('@/view/layout/Layout'),
			children: [
				{
					path: '/tickets',
					name: 'tickets',
					component: () => import('@/view/pages/Tickets.vue'),
					props: true,
				},
			],
		},
		{
			path: '/voip',
			component: () => import('@/view/layout/Layout'),
			children: [
				{
					path: '/voip',
					name: 'voip',
					component: () => import('@/view/pages/VoipPage.vue'),
					props: true,
				},
			],
		},
		{
			path: '/networks',
			component: () => import('@/view/layout/Layout'),
			children: [
				{
					path: '/networks/:serverId/:networkId',
					name: 'network',
					component: () => import('@/view/pages/Network.vue'),
					props: true,
				},
			],
		},
		{
			path: '/admin',
			component: () => import('@/view/layout/Layout'),
			children: [
				{
					path: '/admin',
					name: 'admin',
					component: () => import('@/view/pages/admin/Admin.vue'),
					props: true,
				},
			],
		},
		{
			path: '/admineditsite',
			component: () => import('@/view/layout/Layout'),
			children: [
				{
					path: '/admineditsite/:id',
					name: 'admineditsite',
					component: () => import('@/view/pages/admin/AdminEditSite.vue'),
					props: true,
				},
			],
		},
		{
			path: '/adminnewsite',
			component: () => import('@/view/layout/Layout'),
			children: [
				{
					path: '/adminnewsite',
					name: 'adminnewsite',
					component: () => import('@/view/pages/admin/AdminNewSite.vue'),
					props: true,
				},
			],
		},
		{
			path: '/adminnewaccount',
			component: () => import('@/view/layout/Layout'),
			children: [
				{
					path: '/adminnewaccount',
					name: 'adminnewaccount',
					component: () => import('@/view/pages/admin/AdminNewAccount.vue'),
					props: true,
				},
			],
		},
		{
			path: '/admineditaccount',
			component: () => import('@/view/layout/Layout'),
			children: [
				{
					path: '/admineditaccount/:id',
					name: 'admineditaccount',
					component: () => import('@/view/pages/admin/AdminEditAccount.vue'),
					props: true,
				},
			],
		},
		{
			path: '/admindevice',
			component: () => import('@/view/layout/Layout'),
			children: [
				{
					path: '/admindevice/:id',
					name: 'admindevice',
					component: () => import('@/view/pages/admin/AdminDevice.vue'),
					props: true,
				},
			],
		},
		{
			path: '/adminnocalerts/:alertid?',
			component: () => import('@/view/layout/Layout'),
			children: [
				{
					path: '/adminnocalerts/:alertid?',
					name: 'adminnocalerts',
					component: () => import('@/view/pages/admin/AdminNocAlerts.vue'),
					props: true,
				},
			],
		},
		{
			path: '/releasenotes',
			component: () => import('@/view/layout/Layout'),
			children: [
				{
					path: '/releasenotes',
					name: 'releasenotesindex',
					component: () => import('@/view/pages/admin/ReleaseNotesIndex.vue'),
				},
				{
					path: '/releasenotes/:version',
					name: 'releasenotes',
					component: () => import('@/view/pages/admin/ReleaseNotes.vue'),
					props: true,
				},
			],
		},
		{
			path: '/video',
			component: () => import('@/view/layout/Layout'),
			children: [
				{
					path: '/video',
					name: 'video',
					component: () => import('@/view/pages/Video.vue'),
				},
				{
					path: '/video/admin',
					name: 'AdminVideo',
					component: () => import('@/view/pages/admin/AdminVideo.vue'),
				},
			],
		},
		{
			path: '/app',
			component: () => import('@/view/layout/Minimal.vue'),
			children: [
				{
					path: '/app/video',
					name: 'VideoOnly',
					component: () => import('@/view/pages/Video.vue'),
				},
				{
					path: '/app/profile',
					name: 'AppOnlyProfile',
					component: () => import('@/view/pages/profile/Profile.vue'),
				},
			],
		},
		{
			path: '/srf',
			component: () => import('@/view/layout/Layout'),
			children: [
				{
					path: '/srf',
					name: 'srflist',
					component: () => import('@/view/pages/srf/SrfList.vue'),
				},
				{
					path: '/srf/new',
					name: 'newsrf',
					component: () => import('@/view/pages/srf/SrfForm.vue'),
					props: true,
				},
				{
					path: '/srf/download/:id',
					name: 'downloadsrf',
					component: () => import('@/view/pages/srf/SrfDownload.vue'),
					props: true,
				},
				{
					path: '/srf/:id',
					name: 'srf',
					component: () => import('@/view/pages/srf/SrfView.vue'),
					props: true,
				},
				{
					path: '/srf/:id/edit',
					name: 'editsrf',
					component: () => import('@/view/pages/srf/SrfForm.vue'),
					props: true,
				},
				{
					path: '/srf/:id/closeout',
					name: 'srfcloseoutdoc',
					component: () => import('@/view/pages/srf/SrfCloseout.vue'),
					props: true,
				},
			],
		},
		{
			path: '/issues',
			component: () => import('@/view/layout/HeaderOnly.vue'),
			children: [
				{
					path: '/issues',
					name: 'issues',
					component: () => import('@/view/pages/issues/Issues.vue'),
				},
				{
					path: '/issue/:id',
					name: 'issue',
					component: () => import('@/view/pages/issues/Issue.vue'),
					props: true,
				},
				{
					path: '/issues/new',
					name: 'newissue',
					component: () => import('@/view/pages/issues/IssueReport.vue'),
				},
				{
					path: '/issues/:id/edit',
					name: 'editissue',
					component: () => import('@/view/pages/issues/IssueReport.vue'),
					props: true,
				},
			],
		},
		{
			path: '/error',
			name: 'error',
			component: () => import('@/view/pages/error/Error.vue'),
			children: [
				{
					path: 'error-1',
					name: 'error-1',
					component: () => import('@/view/pages/error/Error-1.vue'),
				},
				{
					path: 'error-2',
					name: 'error-2',
					component: () => import('@/view/pages/error/Error-2.vue'),
				},
				{
					path: 'error-3',
					name: 'error-3',
					component: () => import('@/view/pages/error/Error-3.vue'),
				},
				{
					path: 'error-4',
					name: 'error-4',
					component: () => import('@/view/pages/error/Error-4.vue'),
				},
				{
					path: 'error-5',
					name: 'error-5',
					component: () => import('@/view/pages/error/Error-5.vue'),
				},
				{
					path: 'error-6',
					name: 'error-6',
					component: () => import('@/view/pages/error/Error-6.vue'),
				},
			],
		},
		{
			path: '*',
			redirect: '/404',
		},
		{
			// the 404 route, when none of the above matches
			path: '/404',
			name: '404',
			component: () => import('@/view/pages/error/Error-1.vue'),
		},
	],
});
